/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


require('./src/themes/dark.css');
// require("prismjs/themes/prism-solarizedlight.css")

require("prismjs/plugins/command-line/prism-command-line.css")


